import React from "react"

const Logo = ({ dark = false }) => (
    <svg id="logo" xmlns="http://www.w3.org/2000/svg" width="335.45" height="335.45" viewBox="0 0 335.45 335.45">
        <defs>
          <linearGradient id="linear-gradient" x1="0.08" y1="0.033" x2="0.93" y2="0.978" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="var(--start)"/>
            <stop offset="1" stopColor="var(--stop)"/>
          </linearGradient>
        </defs>
        <g id="Hyperium" transform="translate(-88.275 -88.275)">
          <path id="Path" d="M423.725,256c0,53.238-4.66,103.966-13.086,150.131-2.785,15.258-19.574,22.482-32.058,13.973a216.9,216.9,0,0,0-245.162,0c-12.484,8.509-29.273,1.285-32.058-13.973C92.935,359.966,88.275,309.238,88.275,256s4.66-103.966,13.086-150.131c2.785-15.258,19.574-22.482,32.058-13.973A216.774,216.774,0,0,0,256,129.855,216.774,216.774,0,0,0,378.581,91.9c12.484-8.509,29.273-1.285,32.058,13.973C419.065,152.034,423.725,202.762,423.725,256Z" fill="url(#linear-gradient)"/>
        </g>
    </svg>
)

export default Logo
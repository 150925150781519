import React from "react"

import "../styles/index.sass"

const Layout = ({ children }) => (
  <>
    <main>
      {children}
    </main>
  </>
)

export default Layout
